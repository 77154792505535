/// <reference types="@graphcommerce/next-ui/types" />

declare module "@mui/material/Button" {
  // eslint-disable-next-line no-unused-vars
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    "primary-raised": true;
    "secondary-raised": true;
  }
}

declare module "@mui/material/" {
  // eslint-disable-next-line no-unused-vars
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    "primary-raised": true;
    "secondary-raised": true;
  }
}
declare module "@mui/material/styles" {
  interface Palette {
    gradient: Palette["primary"];
    hyperlink: TypeText & { hover: string };
    placeholder: string;
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    gradient?: PaletteOptions["primary"];
    hyperlink?: Partial<TypeText> & { hover: string };
    placeholder?: string;
  }

  // eslint-disable-next-line no-unused-vars
  interface TypographyVariants {
    subtitle: React.CSSProperties;
    label: React.CSSProperties;
    small: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  // eslint-disable-next-line no-unused-vars
  interface TypographyVariantsOptions {
    subtitle?: React.CSSProperties;
    label?: React.CSSProperties;
    small?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  // eslint-disable-next-line no-unused-vars
  interface TypographyPropsVariantOverrides {
    subtitle: true;
    label: true;
    small: true;
    // TODO: uncomment the following lines once every
    //       unneeded variant is removed from the application
    // body2: false;
    // subtitle1: false;
    // subtitle2: false;
    // h5: false;
    // h6: false;
    // overline: false;
    // button: false;
  }
}

import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/roboto/700.css";

import {
  breakpointVal,
  MuiButtonInline,
  MuiButtonPill,
  MuiButtonResponsive,
  NextLink,
  responsiveVal,
  themeBaseDefaults,
} from "@graphcommerce/next-ui";
import {
  alpha,
  createTheme,
  LinkProps,
  stepConnectorClasses,
  stepIconClasses,
  stepLabelClasses,
  Theme,
} from "@mui/material";
import { Components, PaletteOptions, TypeText } from "@mui/material/styles";

import React from "react";

export const lightPalette: PaletteOptions = {
  mode: "light",
  primary: {
    main: "#EF2427",
    contrastText: "#ffffff",
    light: "#F4D7D9",
    dark: "#FF6B74",
  },
  secondary: {
    main: "#EF2427",
    contrastText: "#ffffff",
    // dark: "#47C489",
    light: "#F4D7D9",
  },
  gradient: {
    main: "linear-gradient(to right, #EB3943, #FF7072)",
    contrastText: "#ffffff",
  },
  background: {
    default: "#ffffff",
    paper: "#ffffff",
    image: "#ffffff",
  },
  divider: "#00000015",
  success: {
    main: "#46AF3D",
  },
  warning: {
    main: "#F2C94C", // Label color
  },
  text: {
    primary: "#000000", // Heading / Main text
    secondary: "#5B5B5B", // Body / Subtitle / Label / Caption text
    disabled: "#A3A3A3", // Muted text
  },
  placeholder: "#C1C1C1",
  hyperlink: {
    primary: "#2F80ED",
    hover: "#EF2427",
    disabled: "#B9B9C3", // TODO
  },
};

function deepMerge(obj1, obj2) {
  // Create a new object that combines the properties of both input objects
  const merged = {
    ...obj1,
    ...obj2,
  };
  // Loop through the properties of the merged object
  for (const key of Object.keys(merged)) {
    // Check if the property is an object
    if (typeof merged[key] === "object" && merged[key] !== null) {
      // If the property is an object, recursively merge the objects
      merged[key] = deepMerge(obj1[key], obj2[key]);
    }
  }
  return merged;
}

const fontSize = (from: number, to: number) =>
  breakpointVal("fontSize", from, to, themeBaseDefaults.breakpoints.values);

const lineHeight = (from: number, to: number) =>
  breakpointVal("lineHeight", from, to, themeBaseDefaults.breakpoints.values);

// Create a theme instance.
const createThemeWithPalette = (palette: PaletteOptions) =>
  createTheme({
    palette,
    ...themeBaseDefaults,
    shape: { borderRadius: 2 },
    typography: {
      fontFamily:
        "Poppins,Roboto,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji",
      // @see docs typography.md
      h1: {
        ...deepMerge(fontSize(32, 48), lineHeight(40, 72)),
        fontWeight: 700,
        color: palette.text?.primary,
      },
      h2: {
        ...deepMerge(fontSize(24, 32), lineHeight(30, 48)),
        fontWeight: 600,
        color: palette.text?.primary,
      },
      h3: {
        ...deepMerge(fontSize(16, 24), lineHeight(20, 40)),
        fontWeight: 600,
        color: palette.text?.primary,
      },
      h4: {
        ...deepMerge(fontSize(14, 16), lineHeight(16, 24)),
        fontWeight: 500,
        color: palette.text?.primary,
      },
      ////////////////////////////// Unused variants
      // Styled for default components compatibility
      h5: {},
      h6: {
        fontSize: "14px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "20px",
        textTransform: "none",
        color: `${palette.text?.secondary} !important`,
      },
      body2: {},
      subtitle2: {},
      overline: {},
      button: {},
      ////////////////
      subtitle1: {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "18px",
        color: palette.text?.secondary,
      },
      label: {
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "16px",
        color: palette.text?.secondary,
      },
      body1: {
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "16px",
        color: palette.text?.secondary,
      },
      caption: {
        fontFamily: "Poppins",
        fontSize: "12px",
        fontWeight: 300,
        lineHeight: "14px",
        color: palette.text?.secondary,
      },
      small: {
        fontFamily: "Poppins",
        fontSize: "12px",
        fontWeight: 300,
        lineHeight: "14px",
        color: palette.text?.secondary,
      },
    },
    spacings: {
      xxs: responsiveVal(10, 16),
      xs: responsiveVal(12, 20),
      sm: responsiveVal(14, 30),
      md: responsiveVal(16, 50),
      lg: responsiveVal(24, 80),
      xl: responsiveVal(40, 100),
      xxl: responsiveVal(80, 160),
    },
    page: {
      horizontal: responsiveVal(10, 30),
      vertical: responsiveVal(10, 30),
    },
    appShell: {
      headerHeightSm: "46px",
      headerHeightMd: "114px",
      appBarHeightMd: "80px",
      appBarInnerHeightMd: "46px",
    },
  });

const createOverrides = (theme: Theme): Components => ({
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        overflowY: "scroll",
        ...theme.typography.body2,
      },
      "::selection": { background: alpha(theme.palette.primary.main, 0.6) },
      "::-moz-selection": {
        background: alpha(theme.palette.primary.main, 0.6),
      },
      "#__next": {
        position: "relative",
      },
      "picture img": {
        filter: "brightness(1.03)",
        willChange: "filter",
      },
      a: {
        color: theme.palette.hyperlink.primary,
        "&:hover": {
          color: theme.palette.hyperlink.hover,
        },
      },
      //
      // Existing GraphCommerce components
      //
      ".ContainerWithHeader-root": {
        ".ContainerWithHeader-head": {
          h2: {
            color: theme.palette.text.primary,
            fontSize: "24px",
            fontWeight: "600",
            lineHeight: "40px",
          },
          a: {
            color: theme.palette.hyperlink.primary,
            "&:hover": {
              color: theme.palette.hyperlink.hover,
            },
          },
        },
      },
      ".SearchForm-root": {
        borderRadius: "30px",
        overflow: "hidden",
        ".MuiInputBase-root": {
          fontSize: "16px",
          height: "38px",
          minHeight: "38px",
          background: "#F7F7F7",
        },
        ".MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        ".MuiInputBase-input": {
          paddingLeft: "25px",
        },
      },

      ".ActionCard-after": {
        "#card-number": {
          height: "50px",
          border: "1px solid grey",
          borderRadius: "8px",
          marginTop: "5px",
          marginBottom: "20px",
          paddingLeft: "10px",
        },
        "#cvv": {
          height: "50px",
          border: "1px solid grey",
          borderRadius: "8px",
          marginTop: "5px",
          marginBottom: "20px",
          paddingLeft: "10px",
        },
        "#expiration-date": {
          height: "50px",
          border: "1px solid grey",
          borderRadius: "8px",
          marginTop: "5px",
          marginBottom: "20px",
          paddingLeft: "10px",
        },
      },

      ".SectionContainer-root": {
        ".AccountAddresses-addresses": {
          ".AccountAddress-actions": {
            ".MuiButtonBase-root": {
              fontSize: "11.5px",
              padding: "10px",
              [theme.breakpoints.up("md")]: {
                fontSize: "16px",
              },
            },
          },
        },
        ".MuiButtonBase-root": {
          fontSize: "16px",
          [theme.breakpoints.up("md")]: {
            fontSize: "24px",
          },
        },
      },
    },
  },

  MuiTypography: {
    defaultProps: {
      variant: "body1",
    },
  },

  // https://mui.com/material-ui/guides/routing/#global-theme-link
  // https://www.graphcommerce.org/docs/framework/links
  MuiLink: {
    defaultProps: {
      component: NextLink,
    } as LinkProps,
  },

  MuiChip: {
    styleOverrides: {
      root: {
        background: "white",
      },
    },
  },

  MuiButtonBase: { defaultProps: { LinkComponent: NextLink } },

  MuiContainer: {
    variants: [
      {
        props: { disableGutters: false },
        style: {
          paddingLeft: theme.page.horizontal,
          paddingRight: theme.page.horizontal,
          [theme.breakpoints.up("sm")]: {
            paddingLeft: theme.page.horizontal,
            paddingRight: theme.page.horizontal,
          },
        },
      },
    ],
  },

  MuiAccordion: {
    variants: [
      {
        props: {},
        style: {
          border: "none",
        },
      },
    ],
  },

  MuiInputBase: {
    styleOverrides: {
      root: {
        fontSize: "16px", // https://css-tricks.com/16px-or-larger-text-prevents-ios-form-zoom/
      },
    },
  },

  MuiButton: {
    defaultProps: { color: "inherit" },
    variants: [
      ...MuiButtonResponsive,
      ...MuiButtonPill,
      ...MuiButtonInline,
      {
        props: {},
        style: {
          display: "flex !important",
          borderRadius: "8px",
          "&:hover": {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.dark,
          },
          "&:disabled": {
            color: theme.palette.text.secondary,
            backgroundColor: "#D5D5D5",
            borderColor: "#D5D5D5",
          },
        },
      } as any,
      {
        props: { variant: "primary" },
        style: {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.main,
        },
      },
      {
        props: { variant: "secondary" },
        style: {
          color: theme.palette.text.primary,
          "&:hover": {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.primary.light,
          },
        },
      },
      {
        props: { variant: "outlined" },
        style: {
          color: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
          "&:hover": {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.light,
          },
        },
      },
      {
        props: { size: "small" },
        style: {
          fontSize: "12px",
          lineHeight: "20px",
          fontWeight: 500,
          padding: "13px 24px",
          width: "max-content",
          height: "36px",
        },
      },
      {
        props: { size: "medium" },
        style: {
          fontSize: "20px",
          lineHeight: "24px",
          fontWeight: 500,
          padding: "12px 24px",
          height: "44px",
        },
      },
      {
        props: { size: "large" },
        style: {
          fontSize: "24px",
          lineHeight: "36px",
          fontWeight: 500,
          padding: "24px",
          height: "48px",
        },
      },
    ],
  },

  MuiIconButton: {
    defaultProps: { color: "inherit" },
    variants: [
      {
        props: {},
        style: {
          borderRadius: "8px",
          // TODO: add box shadow only to whishlist button
          //boxShadow: `0 0 40px -12px ${theme.palette.text.secondary}`,
        },
      },
    ],
  },

  MuiFab: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.background.paper,
        "&:hover": {
          backgroundColor: theme.palette.background.paper,
        },
        color: theme.palette.text.primary,
      },
      colorInherit: {
        backgroundColor: "inherit",
        "&:hover, &:focus": {
          backgroundColor: "inherit",
        },
        boxShadow: "none",
      },
      primary: {
        color: theme.palette.text.primary,
      },
      secondary: {
        color: theme.palette.text.primary,
      },
      extended: {
        fontWeight: 400,
        textTransform: "none",
      },
    },
  },

  MuiTextField: {
    defaultProps: { color: "primary" },
    styleOverrides: {
      root: {
        "& .MuiInputBase-root": {
          fontWeight: 300,
          borderRadius: "6px",
          minHeight: "38px",
        },
      },
    },
    variants: [
      {
        props: { variant: "standard" },
        style: {
          fontStyle: "italic",
          fontSize: "16px",
          lineHeight: "20px",
        },
      },
      {
        props: { variant: "outlined" },
        style: {
          fontSize: "14px",
          lineHeight: "20px",
        },
      },
      {
        props: { variant: "filled" },
        style: {
          "& .MuiInputBase-root": {
            "&:before,&:after": {
              content: "none",
              borderBottom: "none",
            },
            borderRadius: "100px",
          },
          "& .MuiInputBase-input": {
            fontSize: "14px",
            lineHeight: "16px",
            padding: "0 1em",
          },
        },
      },
    ],
  },

  MuiInputLabel: {
    styleOverrides: { root: { fontStyle: "italic", fontWeight: 300 } },
  },

  MuiSelect: {
    defaultProps: {
      variant: "standard",
    },
  },

  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: theme.palette.text.primary,
      },
    },
  },

  MuiCheckbox: {
    styleOverrides: {
      colorPrimary: {
        color: theme.palette.text.disabled,
        "&.Mui-checked": {
          color: theme.palette.primary.main,
        },
      },
      colorSecondary: {
        color: theme.palette.text.disabled,
        "&.Mui-checked": {
          color: theme.palette.secondary.main,
        },
      },
    },

    variants: [
      {
        props: { size: "medium" },
        style: {
          padding: 7,
        },
      },
    ],
  },

  MuiSwitch: {
    styleOverrides: {
      thumb: {
        boxShadow: theme.shadows[6],
      },
    },
  },

  MuiAvatar: {
    styleOverrides: {
      colorDefault: {
        backgroundColor: theme.palette.text.disabled,
      },
    },
  },

  MuiCircularProgress: {
    defaultProps: {
      thickness: 2,
    },
  },

  MuiStepLabel: {
    styleOverrides: {
      root: {
        fontSize: "14px",
        fontFamily: "Poppins",
        fontWeight: "600",
        lineHeight: "20px",
        [`& .${stepLabelClasses.disabled}`]: {
          color: theme.palette.text.disabled,
        },
      },
    },
  },

  MuiStepConnector: {
    styleOverrides: {
      root: {
        [`&:not(${stepConnectorClasses.active})`]: {
          [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.text.disabled,
          },
        },
        [`& .${stepConnectorClasses.line}`]: {
          borderTopWidth: "2px",
        },
        [`&.${stepConnectorClasses.completed}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.primary.main,
          },
        },
        [`&.${stepConnectorClasses.active}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.primary.main,
          },
        },
      },
    },
  },

  MuiStepIcon: {
    styleOverrides: {
      root: {
        [`&:not(${stepIconClasses.active})`]: {
          color: theme.palette.text.disabled,
        },
        [`&.${stepIconClasses.root}`]: {
          width: "28px",
          height: "28px",
        },
        [`&.${stepIconClasses.text}`]: {
          fontSize: "12px",
          fontFamily: "Roboto",
          fontWeight: "700",
          lineHeight: "20px",
        },
      },
    },
  },
});

export const lightTheme = createThemeWithPalette(lightPalette);
lightTheme.components = createOverrides(lightTheme);
