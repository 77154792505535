import { lightTheme } from "@/components/theme/theme";
import { I18nProvider } from "@/lib/i18n/I18nProvider";
import { FramerNextPages } from "@graphcommerce/framer-next-pages";
import { GraphQLProvider } from "@graphcommerce/graphql";
import { GlobalHead } from "@graphcommerce/magento-store";
import {
  CssAndFramerMotionProvider,
  PageLoadIndicator,
} from "@graphcommerce/next-ui";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AppProps } from "next/app";

const queryClient = new QueryClient();

export default function ThemedApp(props: AppProps) {
  return (
    <CssAndFramerMotionProvider {...props}>
      <I18nProvider key="fr" locale="fr">
        <QueryClientProvider client={queryClient}>
          <GraphQLProvider {...props}>
            <ThemeProvider theme={lightTheme}>
              <GlobalHead />
              <CssBaseline />
              <PageLoadIndicator />
              <FramerNextPages {...props} />
            </ThemeProvider>
          </GraphQLProvider>
        </QueryClientProvider>
      </I18nProvider>
    </CssAndFramerMotionProvider>
  );
}
